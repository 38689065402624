import { default as _91classId_93VEEsMzWr3lMeta } from "/vercel/path0/pages/classes/[classId].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as magic21X6Oj0FJcMeta } from "/vercel/path0/pages/magic.vue?macro=true";
export default [
  {
    name: "classes-classId",
    path: "/classes/:classId()",
    meta: _91classId_93VEEsMzWr3lMeta || {},
    component: () => import("/vercel/path0/pages/classes/[classId].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "magic",
    path: "/magic",
    component: () => import("/vercel/path0/pages/magic.vue").then(m => m.default || m)
  }
]